import React from 'react';
import ContactWidget from '../components/contact-widget';
import Layout from '../components/layout';
import Footer from '../components/footer';
import './contact.scss';

import overviewContactLeft from '../images/overview-contact-logo-left.png';
import overviewContactRight from '../images/overview-contact-logo-right.png';

export default function ContactPage(props) {
	return (
		<Layout page="contact" location={props.location}>
			<div style={{ marginTop: '12rem' }}>
				{!props.location?.state?.page && (
					<div className="overview-contact-logos">
						<img src={overviewContactLeft} alt="UXG logo" />
						<img src={overviewContactRight} alt="UXG logo" />
					</div>
				)}
				<ContactWidget showSocial={true} />
				<Footer page="contact" location={props.location} />
			</div>
		</Layout>
	);
}
